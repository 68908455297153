import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { useQuery } from '@apollo/client'
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next'
import { useErrorHandler } from 'react-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { useSlider } from 'components/common/slider/hooks/useSlider'
import { Widgets } from 'utils/constants'
import { Slider } from 'components/common/slider'
import { SliderArrow } from 'components/common/slider-arrow'
import { Grade } from 'components/common/grade'
import { WetstockData } from './wetstock-data'
import { WETSTOCK_QUERY } from './WetstockQueries'
import styles from './Wetstock.module.scss'

const cx = classNames.bind(styles)
const { WETSTOCK } = Widgets

const Wetstock = ({ stationId }) => {
  const [state, dispatch] = useSlider(WETSTOCK)
  const { selectedPricingType, supportedPricingTypes } = usePricingTypes()
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(WETSTOCK_QUERY, {
    variables: {
      stationId,
      supportedPricingTypes,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  useErrorHandler(error)

  const {
    leftCount,
    rightCount,
    leftArrowActive,
    rightArrowActive,
    showArrowsStyle,
    elementWidth,
    elementMargin,
  } = state

  if (loading || !data) {
    return (
      <div className={cx('wetstock-spinner')}>
        <Spinner animation="grow" variant="primary" />
      </div>
    )
  }

  const {
    station: { fuelGrades },
  } = data

  const grades = fuelGrades.filter(data => data.fuelGrade.title !== 'Total')

  const selectedPricingGradeData = grades.map(grade => {
    const metrics = grade.metrics.filter(
      metric => metric.pricingType === selectedPricingType
    )
    return {
      title: grade.fuelGrade.title,
      volume: grade.metrics[0].totalVolume,
      colour: grade.fuelGrade.primary,
      blended: grade.fuelGrade.blended,
      blendedCost: metrics[0].blendedCost,
      replacementCost: metrics[0].replacementCost,
      margin: metrics[0].margin,
      replacementCostTimestamp: metrics[0].replacementCostTimestamp,
      replacementCostError: metrics[0].error,
    }
  })

  const widget = selectedPricingGradeData.map(
    (
      {
        id,
        title,
        volume,
        colour,
        blended,
        blendedCost,
        replacementCost,
        margin,
        replacementCostTimestamp,
        replacementCostError,
      },
      i
    ) => (
      <div className={cx('wetstock-items-container')} key={i}>
        <Grade
          key={`grade-${i}`}
          id={id}
          title={title}
          volume={volume}
          colour={colour}
          blended={blended}
          width={elementWidth}
        />
        <WetstockData
          key={`wetstock-${i}`}
          blendedCost={blendedCost}
          replacementCost={replacementCost}
          margin={margin}
          replacementCostTimestamp={replacementCostTimestamp}
          replacementCostError={replacementCostError}
        />
      </div>
    )
  )

  return (
    <div className={cx('wetstock-container')}>
      <div className={cx('wetstock-header')}>
        <div className={cx('wetstock-title')}>
          {t('widgets.wetstockAnalysis.title')}
        </div>
        <div className={cx('slider-arrow-container')} style={showArrowsStyle}>
          <SliderArrow
            direction={'left'}
            handleClick={() => dispatch({ type: 'handleLeftClick' })}
            isActive={leftArrowActive}
          />
          <SliderArrow
            style={cx('slider-arrow')}
            direction={'right'}
            handleClick={() => dispatch({ type: 'handleRightClick' })}
            isActive={rightArrowActive}
          />
        </div>
      </div>
      <div className={cx('wetstock-slider')}>
        <Slider
          leftClick={leftCount}
          rightClick={rightCount}
          handleLeftArrowActive={dispatch}
          handleRightArrowActive={dispatch}
          showArrows={dispatch}
          singleElementWidth={elementWidth}
          elementCount={grades.length}
          elementMargin={elementMargin}
          additionalContainerWidth={30}
        >
          {widget}
        </Slider>
      </div>
    </div>
  )
}

Wetstock.propTypes = {
  stationId: PropTypes.string.isRequired,
}

const FallbackComponent = () => <WidgetFallback title="Wetstock analysis" />

const WetstockWithErrorBoundary = withEdgeErrorBoundary(
  Wetstock,
  FallbackComponent
)

export { WetstockWithErrorBoundary as Wetstock }
